
* {
  margin: 0;
  padding: 0;
  font-family: Arial;
 
}

.body {
  font-family: Arial;
  height: 100vh;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #616464;
  color:rgb(12, 12, 12);
  font-family: Arial;
}

ul {
  margin-left: 1.5em;
}

li {
  margin: 0.5em 0;
}

.componentlist {
  display: none;
  left:0px;
  text-align: left;
  color:#000000;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;

}

.layerResetlayerCechoutLeft {
  width: 250px;
  height: 80px;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
 
} 

.status-bar {
  min-height: 20px;
}


.tablefontsize {
  padding-top: 2px;
  padding-left: 2px;
  left:0px;
  text-align: left;
  color:#0b0b0b;
  font-size: 11px;
  
}

.layerResetlayerCechoutRight {
  width: 250px;
  height: 250px;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
 
} 
.totalpriceimg {
  position: absolute;
  width: 200px;
  top: 0;
  left: 20px;
  text-align: left;

}

.totalpriceimgPh {
  position: absolute;
  width: 200px;
  top: 85px;
  left: 20px;
  text-align: left;

}

.chekoutbtnimg {
  position: absolute;
  width: 200px;
  top: 165px;
  left: 90px;
  text-align: left;

}

.totalpricetxt {
  position: absolute;
  width: 200px;
  top: 110px;
  left: 30px;
  text-align: left;
  color: #de5a27;
}

.weigstHeight {
    position: absolute;
    width: 200px;
    top: 20px;
    left: 20px;
    text-align: left;

}

.layerReset {
  width: 140px;
  height: 120px;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  transition: 0.5s;
} 

.verifydatastyle {
  color: rgb(255, 255, 255);
  text-align: center;
}

.reset-all {
  position: absolute;
  width: 70px;
  top: 0;
  left: 0;
  text-align: left;
  cursor: pointer;
} 

.remove-walls {
  position: absolute;
  width: 70px;
  top: 50px;
  right: 10px;
  text-align: right;
  cursor: pointer;
  margin-bottom: 10px;
} 

input[type=text] {
  padding:10px;
  border:1;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.1);
}

.layerAbFtO {
  width: 35vw;
  height: 30px;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  transition: 0.5s;
} 

.ftLabels {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: left;

}

.input-width-ft {
  position: absolute;
  width: 12vw;
  right: 24.5vw;
  text-align: right;
  cursor: pointer;
}

.input-width-ft2 {
  position: absolute;
  width: 12vw;
  right: 12.5vw;
  text-align: right;
  cursor: pointer;
}

.input-width-ft3 {
  position: absolute;
  width: 12vw;
  right: 0.5vw;
  text-align: right;
  cursor: pointer;
}

.layerAb {
  width: 120px;
  height: 120px;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  display: inline-block;
  transition: 0.5s;
} 

.up-arrow {
  position: absolute;
  width: 40px;
  top: 10px;
  left:40px;
  text-align: center;
  cursor: pointer;
} 
.left-arrow {
  position: absolute;
  width: 40px;
  top: 40px;
  left:10px;
  text-align: center;
  cursor: pointer;
} 

.right-arrow {
  position: absolute;
  width: 40px;
  top: 40px;
  right:10px;
  text-align: center;
  cursor: pointer;
} 
.down-arrow {
  position: absolute;
  width: 40px;
  top: 70px;
  right:40px;
  text-align: center;
  cursor: pointer;
}

.weight-height-figures {
  left:0px;
  text-align: left;
  color:#ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%
}

.contact-row {
  width: 100vw;
  display: flex;
  margin-top: 2%;
  margin-bottom: 3%;
  text-align: left;
  justify-content: space-between;
  
}

.contact-box-1 {
  flex-basis: 49%;
  left:  0;
  box-sizing: border-box;
 
 
}


.loadingContainer {
  color:#ffffff;;
  font-size: 1.3rem;
  margin: auto;
}
.labelstyle {
  width:50px;
  padding-left: 15px;
  border-width: 2px;
  border-color: #0d0d0d;
  font-size: 0.9rem;
}

table {
 width: 100%;
}

tr {
  min-height: 20;
  height: 20px;
}

.input {
  width:50px;
  font-size: 1.0rem;
  border: 2px;
  border-radius: 4px;
  border-color: #de5a27;
}

.image-buttons {
  background-color: transparent;
  border: #ffffff;
  border-radius: 4px;
  border-color: transparent;
  cursor: pointer;
}

.image-buttons:hover {
  background-color: transparent;
  border: #ffffff;
  border-radius: 4px;
  border-color: #de5a27;
}

.add-remove{
  color:#de5a27;
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 5px;
  margin-right: 5px;
}

.brand-statement-txt p {
  color:#de5a27;
  font-size: 1.0rem;
  font-weight: 550;
  margin-left: 5px;
  margin-right: 5px;
} 
.brand-statement-txt2 {
  color:#de5a27;
  font-size: 1.0rem;
  font-weight: 550;
  margin-left: 5px;
  margin-right: 5px;
}

.checkoutBlock {
  width: 35vw;
}

.message p{
  color:#5f5d5d;;
  font-size: 0.85rem;
  font-weight: 550;
  margin-left: 5px;
  margin-right: 5px;
} 

.brand-content-span {
  font-family: Arial;
  color: #595757;
  font-weight: 550; 
  font-size: 1.0rem;
  margin-left: 5px;
  margin-right: 5px;
}

.brand-content-span1 {
  font-family: Arial;
  color: #595757;
  font-weight: 550; 
  font-size: 1.0rem;
 
}

.dimensions {
  margin: 0px;
  padding-top: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dimensionsOn {
  margin-top: 0;
  display: block;
}

.dimensionsOff {
  display:none;
}

.modulesOn {
  display: flex;
}

.modulesOff {
  display: none;
}

.customizeOn {
  display: block;
}

.customizeOff {
  display: none;
}

.colorOn {
  display: block;
}

.colorOff {
  display: none;
}

.addpartsOn {
  display: block;
}

.addpartsOff {
  display: none;
}

.removepartsOn {
  display: block;
}

.removepartsOff {
  display: none;
}

.addRemovepartsOn {
  display: block;
}

.addRemovepartsOff {
  display: none;
}

.addRemSepOn {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.addRemSepOff {
  display: none;
}

.addRemHandleOn {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.addRemHandleOff {
  display: none;
}

.second4ColorOn {
  display: flex;
}

.second4ColorOff {
  display: none;
}

.checkoutOn {
  display: block;
}

.checkoutOff {
  display: none;
}

.row {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.grid-row {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.main-row {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.card {
  font-family: Arial;
  height: 100vh;
  border-radius: 5px;
  padding: 2rem;
  width: 80vw;
  color:rgb(12, 12, 12);
  background-color:  #ffffff;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 1%;
  margin-bottom: 1%;
  border: 2px;
  border-style:groove;
  border-color: #de5a27;

}

h5 {
  color: #de5a27;
}

.top-bar {
  font-family: Arial;
  height: fit-content;
  min-height: 170px;
  box-shadow: #4a5a73;
  border: #0d0d0d;
  border-width: 2px;
  margin-top: 10px;
  background-color: #ffffff;
}

.canvasArea {
  height: 500px;
}

.white-Title {
  color: #595757;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}

h4 {
  color: white;
}

.title-image-box {
 margin: 0px;
}

.table-grid {
  font-family: Arial;
  text-align: left;
  vertical-align: top;
  text-align: left;
  flex-basis: 10%;
  background: transparent;
  color:#595757;
  padding-top: 0;
  padding-bottom: 0;
  transition: 0.3s;

}
.table-grid2 {
  font-family: Arial;
  vertical-align: top;
  text-align: left;
  flex-basis: 30%;
  background: transparent;
  color:#595757;
  padding-top: 0;
  padding-bottom: 0;
  transition: 0.3s;

}

.canvas-card {
  font-family: Arial;
  height: 100vh;
  vertical-align: top;
  text-align: left;
  flex-basis: 65%;
  background: transparent;
  color:rgb(12, 12, 12);
  padding-top: 0;
  padding-bottom: 0;
  transition: 0.3s;

}

.right-panel-card {
  font-family: Arial;
  height: 100vh;
  vertical-align: top;
  text-align: left;
  flex-basis: 35%;
  background: #ffffff;
  color:rgb(12, 12, 12);
  padding-top: 1%;
  padding-left: 0.5%;
  transition: 0.3s;


}

.addLabel {
  background-color: white;
  border: 3px solid #de5a27;
  border-radius: 5px;
  color: #de5a27;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
  width: 100%;
}

.blankLabel {
  background-color: transparent;
  border: 0px;
  padding: 05px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 4px;
  margin: 0px;
  width: 100%;
}

.buttonAdd {
  background-color: #de5a27;
  border: 3px solid #ffffff ;
  border-radius: 5px;
  color: white;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
  width: 100%;
}

.button {
  background-color: #de5a27;
  border: 3px solid #ffffff ;
  border-radius: 5px;
  color: white;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8rem;
  margin: 4px 3px;
  cursor: pointer;
  width: 100%;
}

.button2 {
  background-color: #de5a27;
  border: 3px solid #ffffff ;
  border-radius: 5px;
  color: white;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.0rem;
  margin: 4px 3px;
  cursor: pointer;
  width: 100%;
}

.color-button1 {
  background-color: #af8a6f;
  border: 3px solid #a3a0a0;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button1:hover {
  border: 3px solid #e75a29 ;
}

.color-button2 {
  background-color: #a6a6a6;
  border: 3px solid #a3a0a0;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button2:hover {
  border: 3px solid #e75a29;
}

.color-button3 {
  background-color: #0d0d0d;
  border: 3px solid #a3a0a0;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button3:hover {
  border: 3px solid #e75a29;
}

.color-button4 {
  background-color: #f8f8f8;
  border: 3px solid #a3a0a0;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button4:hover {
  border: 3px solid #e75a29 ;
}

.color-button5 {
  background-color: #a4b60c;
  border: 3px solid #a3a0a0;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button5:hover {
  border: 3px solid #e75a29;
}

.color-button6 {
  background-color: #ff635a;
  border: 3px solid #a3a0a0;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button6:hover {
  border: 3px solid #e75a29;
}

.color-button7 {
  background-color: #4a5a73;
  border: 3px solid #a3a0a0;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button7:hover {
  border: 3px solid #e75a29;
}

.color-button8 {
  background-color: #8d9e94;
  border: 3px solid #a3a0a0;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button8:hover {
  border: 3px solid #e75a29 ;
}

.color-button9 {
  background-color: #ff635a;
  border: 3px solid #272626 ;
  color: white;
  padding: 20px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 3px;
  cursor: pointer;
}

.color-button9:hover {
  border: 3px solid #e75a29 ;
}

.material-button {
  background-color: transparent;
  border: 3px solid #ffffff ;
  color: white;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.material-button:hover {
  border: 3px solid #e75a29 ;
}


.phoenix-text {
  font-family: Arial;
  color: e95422;
}
.product-canvas {
  font-family: Arial;
  width: 60vw;
  height: 70vh;
  background:white;
  border-width: 20px;
  border-color: #de5a27;
  box-shadow:  10px 15px 15px 6px #585858;
  border-radius: 0px;
  margin-bottom:20px

}

.colors {
display:flex;
flex-direction:row;
}


.modules {
  margin-left:0;
  margin-right: 1%px;

}

.segments {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
  color:#5f5d5d;;
}
.segments2 {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #5f5d5d;
}

.navigator {
margin:auto;
padding-left: 25px;
padding-right: 25px;
}

.navigatorX {
  margin:auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0px;
  height: fit-content;
  }

input[type=number] {
  padding:10px;
  margin:10px 0; 
  max-width: 30px;
}


.slidecontainer {
  width: 100%;
}

.slider {

  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

@media (max-width: 1080px) {
  .row {
      flex-direction: column;
  }
}

@media (max-width: 700px) {
  .row {
      flex-direction: column;
  }
}

.colors div {padding:8px;}
